<template>
    <div class="flex flex-col justify-between">
        <div>
            <template v-if="showToolbar">
                <v-card-header>
                    <div class="flex items-center justify-between">
                        <v-text class="hidden sm:inline">
                            <template v-if="searchQuery">
                                Showing results for "{{ searchQuery }}".
                            </template>

                            <template v-else>
                                All Results
                            </template>
                        </v-text>

                        <v-form class="w-full sm:w-auto col-span-1 order-3" @submit.prevent="submitSearch">
                            <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                                <v-form-input ref="searchQueryInput" :value="searchQuery" type="text" placeholder="Search ..." class="w-full" />
                                <v-button v-show="false" /> <!-- catch click on sibling when enter is pressed -->

                                <v-button v-show="searchQuery && searchQuery.length > 0" color="danger" @click="searchQuery = null">
                                    <i class="fas fa-times" />
                                    <span class="ml-2 sm:hidden">
                                        Clear
                                    </span>
                                </v-button>

                                <v-button v-show="false" type="submit" color="light">
                                    <v-icon class="fa fa-search" />
                                    <span class="ml-2 sm:hidden">
                                        Search
                                    </span>
                                </v-button>
                            </div>
                        </v-form>
                    </div>
                </v-card-header>
            </template>

            <v-table-container :class="(items.data && items.data.length) ? 'border-b-2 border-default' : ''">
                <v-table>
                    <v-table-head>
                        <v-table-row>
                            <template v-if="isLoading || isInitializing">
                                <v-table-header colspan="100%">
                                    Loading, please wait.
                                </v-table-header>
                            </template>

                            <template v-if="!isLoading && !isInitializing">
                                <template v-for="(column, columnIndex) in tableColumns">
                                    <v-table-header v-if="column.visible !== false" :key="'column_' + columnIndex" :class="'text-' + column.align || 'left'" :full-width="column.fullWidth">
                                        {{ column.label }}
                                    </v-table-header>
                                </template>
                            </template>
                        </v-table-row>
                    </v-table-head>

                    <v-table-body>
                        <v-table-row v-if="isLoading || isInitializing">
                            <v-table-data colspan="100%" align="center">
                                <v-loader />
                            </v-table-data>
                        </v-table-row>

                        <template v-if="!isLoading && !isInitializing">
                            <slot name="rows" :rows="dataCollection">
                                <v-table-row v-for="(row, rowIndex) in dataCollection" :key="'row_' + rowIndex" @click="$emit('click-row', row)">
                                    <template v-for="(column, columnIndex) in tableColumns">
                                        <v-table-data v-if="column.visible !== false" :key="'column_' + columnIndex" :align="column.align || 'left'" :full-width="column.fullWidth">
                                            <slot :name="'column_' + columnIndex" :row="row" :rowIndex="rowIndex" :column="column" :columnIndex="columnIndex">
                                                <template v-if="typeof column.formatter == 'function'">
                                                    <v-text :class="`${column.truncate ? 'max-w-xs truncate' : ''} text-${column.align || 'left'}`">
                                                        {{ column.formatter(row) }}
                                                    </v-text>
                                                </template>

                                                <template v-else>
                                                    <v-text :class="`${column.truncate ? 'max-w-xs truncate' : ''} text-${column.align || 'left'}`">
                                                        {{ row[columnIndex] || "" }}
                                                    </v-text>
                                                </template>
                                            </slot>
                                        </v-table-data>
                                    </template>
                                </v-table-row>
                            </slot>

                            <v-table-row-no-results v-if="!items.data || !items.data.length" :message="noResultMessage" />
                        </template>
                    </v-table-body>
                </v-table>
            </v-table-container>
        </div>

        <v-card-footer v-if="items.meta && items.data && items.data.length">
            <nav class="flex items-center justify-between">
                <div class="hidden sm:block">
                    <template v-if="!isLoading && !isInitializing">
                        <v-text>
                            Showing
                            {{ ' ' }}
                            <span class="font-medium">{{ items.meta.from }}</span>
                            {{ ' ' }}
                            to
                            {{ ' ' }}
                            <span class="font-medium">{{ items.meta.to }}</span>
                            {{ ' ' }}
                            of
                            {{ ' ' }}
                            <span class="font-medium">{{ items.meta.total }}</span>
                            {{ ' ' }}
                            results
                        </v-text>
                    </template>

                    <template v-else>
                        <v-text>
                            Loading, please wait.
                        </v-text>
                    </template>
                </div>

                <template v-if="items.meta">
                    <div class="flex-1 flex justify-between sm:justify-end space-x-default">
                        <v-button color="primary" :disabled="parseInt(page) <= 1" @click.stop="page = parseInt(page) - 1">
                            Previous
                        </v-button>

                        <v-button color="primary" :disabled="parseInt(page) >= items.meta.last_page" @click.stop="page = parseInt(page) + 1">
                            Next
                        </v-button>
                    </div>
                </template>
            </nav>
        </v-card-footer>

        <v-card-footer v-if="showFooter && (!items.meta && items.data && items.data.length)">
            <nav class="flex items-center justify-between">
                <div class="hidden sm:block">
                    <template v-if="!isLoading && !isInitializing">
                        <v-text>
                            Showing all {{ items.data.length }} results
                        </v-text>
                    </template>

                    <template v-else>
                        <v-text>
                            Loading, please wait.
                        </v-text>
                    </template>
                </div>
            </nav>
        </v-card-footer>
    </div>
</template>

<script>
import Collect from "collect.js";
import _ from "lodash";

export default {
    name: "ModelTable",
    props: {
        dataFunction: {
            type: Function,
            required: false,
            default: null,
        },
        service: {
            type: Object,
            required: false,
            default: () => {},
        },
        module: {
            type: String,
            required: false,
            default: () => "",
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
        columns: {
            type: [Object, Boolean],
            required: false,
            default: () => false,
        },
        noResultMessage: {
            type: String,
            required: false,
            default: () => "No results found.",
        },
        showToolbar: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        showFooter: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },
    data() {
        return {
            isLoading: true,
            page: this.$route.query.page || 1,
            searchQuery: this.$route.query.searchQuery || null,
            serviceItems: [],
            dataFunctionItems: {},
            fetchQuery: {
                page: this.$route.query.page || 1,
                searchQuery: null,
                game_id: this.selectedGame,
            },
        };
    },
    computed: {
        items() {
            if (this.dataFunction) {
                return this.dataFunctionItems;
            }

            if (this.service) {
                return this.serviceItems;
            }

            if (this.module) {
                return this.$store.getters[`${this.module}/getItems`] || [];
            }

            return [];
        },
        tableColumns() {
            if (!this.columns) {
                if (this.items.data && this.items.data.length) {
                    const columns = {};

                    Object.keys(this.items.data[0]).forEach((key) => {
                        columns[key] = {
                            label: key,
                        };
                    });

                    return columns;
                }
            }

            return this.columns;
        },
        dataCollection() {
            return Collect(this.items.data);
        },

        /**
         * @todo Write a description.
         */
        selectedGame() {
            const result = this.$store.getters["appModule/getSettings"].selectedGame;

            if (result === "null") {
                return null;
            }

            return result;
        },
    },
    watch: {
        selectedGame: {
            handler(gameId) {
                if (`${this.$route.query.game_id}` === `${gameId}`) {
                    return;
                }

                const pickedValue = _.pickBy({
                    ...this.$route.query,
                    game_id: gameId,
                }, (item) => {
                    if (Array.isArray(item) && !item.length) {
                        return false;
                    }

                    return !!item;
                });

                if (!this.isEqualObject(this.$route.query, pickedValue)) {
                    this.$router.replace({ query: pickedValue });
                }

                this.fetchQuery = { ...this.fetchQuery, game_id: gameId };
                this.$emit("selected-game-updated");
            },
            immediate: true,
        },
        query: {
            handler(value) {
                // Set page to the query string value on initial page load.
                // Set page to 1 every query update.
                this.page = this.isInitializing ? (this.$route.query.page || this.page) : 1;

                this.fetchQuery = {
                    ...this.fetchQuery,
                    ...value,
                    // Fetch the actual page.
                    page: this.page,
                };
            },
            deep: true,
            immediate: true,
        },
        fetchQuery: {
            handler(value) {
                this.$nextTick(() => {
                    if (!this.isLoading) {
                        this.toggleLoading();
                    }

                    this.replaceQueryStrings(value);

                    this.fetchItems(value)
                        .finally(() => {
                            this.isLoading = false;
                        });
                });
            },
            deep: true,
            immediate: true,
        },
        page: {
            handler(value) {
                this.fetchQuery.page = value;
            },
        },
        searchQuery: {
            handler(value) {
                this.fetchQuery.searchQuery = value;
            },
        },
    },
    created() {
    },
    mounted() {
        this.$nextTick(() => {
            this.toggleInitialize(1500);
        });
    },
    methods: {
        /**
         * @todo Write a description.
         */
        submitSearch() {
            this.isLoading = true;
            let searchQuery = this.$refs.searchQueryInput.input;

            if (!searchQuery || searchQuery.toString().length < 1) {
                searchQuery = null;
                this.isLoading = false;
            }

            this.searchQuery = searchQuery;
            this.page = 1;
        },

        /**
         * @todo Write a description.
         */
        fetchItems(payload) {
            if (payload.paginated === undefined) {
                payload.paginated = true;
            }

            if (this.dataFunction) {
                return this.fetchAllDataFunctionItems(payload);
            }

            if (this.service) {
                return this.fetchAllServiceItems(payload);
            }

            if (this.module) {
                return this.fetchAllItems(payload);
            }

            console.error("No function, service or module found.");
            return false;
        },

        /**
         * @todo Write a description.
         */
        fetchAllDataFunctionItems(payload = {}) {
            return new Promise((resolve, reject) => {
                this.dataFunction(payload)
                    .then((response) => {
                        this.dataFunctionItems = response.data;
                        resolve();
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },

        /**
         * @todo Write a description.
         */
        fetchAllServiceItems(payload = {}) {
            return new Promise((resolve, reject) => {
                this.service.index(payload)
                    .then((response) => {
                        this.serviceItems = response.data;
                        resolve();
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },

        /**
         * @todo Write a description.
         */
        fetchAllItems(payload = {}) {
            return new Promise((resolve, reject) => {
                this.$store.dispatch(`${this.module}/fetchAllItems`, payload)
                    .then((response) => {
                        this.items.data = response.data;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        /**
         * @todo Write a description.
         */
        sortObjectKeys(object) {
            return _.sortBy(Object.keys(object)).map((key) => (object[key]));
        },

        /**
         * @todo Write a description.
         */
        isEqualObject(objectA = {}, objectB = {}) {
            objectA = this.stringifyObjectValues(objectA);
            objectB = this.stringifyObjectValues(objectB);

            return (JSON.stringify(this.sortObjectKeys(objectA)) === JSON.stringify(this.sortObjectKeys(objectB)));
        },

        /**
         * @todo Write a description.
         */
        stringifyObjectValues(object) {
            return _.transform(object, (result, value, key) => {
                result[key] = value;

                if (typeof value === "number" || typeof value === "boolean") {
                    result[key] = `${value}`;
                }

                if (Array.isArray(value)) {
                    if (value.length > 1) {
                        const parsedArray = [];

                        value.forEach((item) => {
                            if (typeof item === "number" || typeof item === "boolean") {
                                item = `${item}`;
                            }

                            parsedArray.push(item);
                        });

                        result[key] = parsedArray;
                    }

                    result[key] = `${value[0]}`;
                }
            }, {});
        },

        /**
         * Method for replacing the query string without returning NavigationDuplicated errors.
         */
        replaceQueryStrings(value) {
            const pickedValue = _.pickBy({
                ...this.$route.query,
                ...value,
            }, (item, key) => {
                if (key === "paginated") {
                    return false;
                }

                if (Array.isArray(item) && !item.length) {
                    return false;
                }

                if (typeof item === "boolean") {
                    return true;
                }

                return !!item;
            });

            if (!this.isEqualObject(this.$route.query, pickedValue)) {
                this.$router.replace({
                    query: {
                        ...pickedValue,
                    },
                });
            }
        },
    },
};
</script>
